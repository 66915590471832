<!-- 主页全局配置 -->
<template>
	<div>
		<!-- overflow: hidden;超出范围隐藏 -->
		<el-container style="position: absolute;left: 0; top: 0; bottom: 0;right: 0;overflow: hidden;">
			<!-- 顶部导航 -->
			<el-header class="d-flex align-items-center" style="background: #095abd;">
				<a class="h5 text-light mb-0 mr-auto">{{ $conf.logo }}</a>
				<el-menu :default-active="navBar.active" mode="horizontal" @select="handleSelect" background-color="#095abd"
				 text-color="#fff" active-text-color="#ffd04b">
					<el-menu-item :index="index|numToString" v-for="(item,index) in navBar.list" :key="index">{{item.name}}</el-menu-item>
					<el-submenu index="100">
						<template slot="title">
							<!-- 登录后显示的头像和用户名 -->
							<el-avatar size="small" :src="user.avatar ? user.avatar : 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"></el-avatar>
							{{user.name}}
						</template>
						<el-menu-item index="100-1">修改</el-menu-item>
						<el-menu-item index="100-2">退出</el-menu-item>
					</el-submenu>
				</el-menu>
			</el-header>

			<!-- 内容 -->
			<el-container style="height: 100%;">
				<!-- 左边布局 -->
				<el-aside width="180px" >
					<el-menu :default-active="slideMenuActive" @select="slideSelect" style="height: 100%;background-color: #1f2138">
						<el-menu-item :index="index | numToString" v-for="(item,index) in slideMenus" :key="index" class="leftbuju">
							<i :class="item.icon"></i>
							<span slot="title">{{item.name}}</span>
						</el-menu-item>
					</el-menu>

				</el-aside>

				<!-- 右边布局 -->
				<el-main class="bg-light" style="padding-bottom: 60px;position: relative;"  v-loading="loading">

					<!-- 面包屑导航 -->
					<div class="border-bottom mb-3 bg-white" style="padding: 20px; margin: -20px;" v-if="bran.length > 0">
						<el-breadcrumb separator-class="el-icon-arrow-right">
							<el-breadcrumb-item :to="{ path: item.path }" v-for="(item,index) in bran" :key="index">
								{{item.title}}
							</el-breadcrumb-item>
						</el-breadcrumb>
					</div>
					<!-- 主内容 -->
					<router-view></router-view>

					<!-- 回到顶部 -->
					<el-backtop target=".el-main" :bottom="100">
						<div style="{height: 100%;width: 100%;
							background-color: #f2f5f6;
							box-shadow: 0 0 6px rgba(0,0,0, .12);
							text-align: center;
							line-height: 40px;
							color: #1989fa;}">
							UP
						</div>
					</el-backtop>
				</el-main>
			</el-container>
		</el-container>
		<!-- <router-view></router-view> -->
	</div>
</template>

<script>
	import common from '@/common/mixins/common.js'; // 引入 全局共公样式
	import { mapState } from 'vuex'; // 引入 全局 公共数据
	export default {
		mixins: [common],
		provide() {
			return {
				layout: this
			}
		},
		data() {
			return {
				bran: [],
				loading: false
			}
		},
		created() {
			// 获取面包屑导航
			this.getRouterBran()
			// 初始化选中菜单
			this.__initNavBar()
		},
		// 页面监听器
		watch: {
			'$route'(to, from) {
				this.navBar.list.forEach((item, index) => {
					let i = item.submenu.findIndex(v => v.pathname === to.name)
					if (i !== -1) {
						this.navBar.active = index.toString()
						this.slideMenuActive = i.toString()
					}
				})

				// 本地存储
				localStorage.setItem('navActive', JSON.stringify({
					top: this.navBar.active || '0',
					left: this.slideMenuActive || '0'
				}))
				this.getRouterBran()
			}
		},
		// 计算属性
		computed: {
			// 引入store-modules的user模块里面的user(存储用户相关的数据)
			...mapState({
				user: state => state.user.user,
				navBar: state => state.menu.navBar
			}),
			slideMenuActive: {
				get() {
					let item = this.navBar.list[this.navBar.active]
					return item ? item.subActive : '0'
				},
				set(val) {
					let item = this.navBar.list[this.navBar.active];
					if (item) {
						item.subActive = val;
					}
				}
			},
			slideMenus() {
				let item = this.navBar.list[this.navBar.active]
				return item ? item.submenu : []
			}
		},
		// 点击导航获得索引
		methods: {
			// 显示loading
			showLoading() {
				this.loading = true
			},
			// 隐藏loading
			hideLoading() {
				this.loading = false
			},
			
			// 获取本地存储
			__initNavBar() {
				let r = localStorage.getItem('navActive')
				if (r) {
					r = JSON.parse(r)
					this.navBar.active = r.top
					this.slideMenuActive = r.left
				}
			},
			// 获取面包屑导航
			getRouterBran() {
				let b = this.$route.matched.filter(v => v.name)
				let arr = []
				b.forEach((v, k) => {
					// 过滤layout和index
					if (v.name === 'index' || v.name === 'layout') return
					arr.push({
						name: v.name,
						path: v.path,
						title: v.meta.title
					})
				})
				if (arr.length > 0) {
					arr.unshift({
						name: 'index',
						path: '/index',
						title: '后台首页'
					})
				}
				this.bran = arr
			},
			// 顶部导航 
			handleSelect(key, keyPath) {
				if (key === '100-1') {
					return console.log('修改资料')
				}
				if (key === '100-2') {
					// 退出登录
					return this.logout()
				}
				this.navBar.active = key
				// 默认选中跳转到当前激活
				this.slideMenuActive = '0'
				if (this.slideMenus.length > 0) {
					this.$router.push({
						name: this.slideMenus[this.slideMenuActive].pathname
					})
				}
			},
			slideSelect(key, keyPath) {
				this.slideMenuActive = key
				// 跳转到指定页面
				this.$router.push({
					name: this.slideMenus[key].pathname
				})
			},
			// 退出登录
			logout() {
				this.axios.post('/admin/logout', {}, {
					token: true,
					loading: true
				}).then(res => {
					this.$message('退出成功')
					// 清除状态和存储
					this.$store.commit('logout')
					// 返回到登录页
					this.$router.push({
						name: "login"
					})
				}).catch(err => {
					console.log(err.response);
					// 清除状态和存储
					this.$store.commit('logout')
					// 返回到登录页
					this.$router.push({
						name: "login"
					})
				})
			}

		}
	}
</script>

<style>
.leftbuju{
	color: #FFFFFF;
}
.leftbuju:hover{
	color: #1f2138;
}
</style>
